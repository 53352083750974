<template>
  <v-row>
    <v-col cols="3">
      <label>Сумма ПП</label>
      <input-numeric
        :value="localState.sum"
        :fraction="2"
        :disabled="isDisabled('sum')"
        :background-color="isDisabled('sum') ? 'grey lighten-2': 'white'"
        @input="update('sum', $event)"
      />
    </v-col>
    <v-col cols="2">
      <label>Валюта</label>
      <v-autocomplete
        :value="localState.currency_letter"
        auto-select-first
        :items="currencies"
        item-text="letterCode"
        item-value="letterCode"
        outlined
        dense
        hide-details="auto"
        :disabled="isDisabled('currency_letter')"
        :background-color="isDisabled('currency_letter') ? 'grey lighten-2': 'white'"
        :filter="filterBySearchField"
        @change="update('currency_letter', $event)"
      />
    </v-col>
    <v-col cols="7">
      <label>Вид платежа</label>
      <v-autocomplete
        :value="localState.code"
        auto-select-first
        :items="paymentTypes"
        outlined
        dense
        item-text="search"
        item-value="code"
        hide-details="auto"
        :disabled="isDisabled('code')"
        :background-color="isDisabled('code') ? 'grey lighten-2': 'white'"
        :filter="filterBySearchField"
        @change="update('code', $event)"
      />
    </v-col>
    <v-col cols="2">
      <label>Номер ПП</label>
      <v-text-field
        :value="localState.payment_doc_number"
        :disabled="isDisabled('payment_doc_number')"
        outlined
        dense
        hide-details="auto"
        :background-color="isDisabled('payment_doc_number') ? 'grey lighten-2': 'white'"
        @input="update('payment_doc_number', $event)"
      />
    </v-col>
    <v-col cols="2">
      <label>Дата ПП</label>
      <custom-date-picker
        :disabled="isDisabled('payment_doc_number')"
        :value="localState.payment_date"
        @change="update('payment_date', $event)"
      />
    </v-col>
    <v-col cols="4">
      <label>Способ уплаты</label>
      <v-autocomplete
        :value="localState.payment_type"
        auto-select-first
        :items="availablePaymentModes"
        item-text="search"
        item-value="code"
        outlined
        dense
        hide-details="auto"
        :disabled="isDisabled('payment_type')"
        :background-color="isDisabled('payment_type') ? 'grey lighten-2': 'white'"
        :filter="filterBySearchField"
        @change="update('payment_type', $event)"
      />
    </v-col>
    <v-col cols="4">
      <label>Плательщик {{ individual ? '(Личный номер)' :'(УНП)' }}</label>
      <v-text-field
        :value="localOwnerIdentity"
        outlined
        dense
        hide-details="auto"
        :disabled="isDisabled('owner_identity')"
        :background-color="isDisabled('owner_identity') ? 'grey lighten-2': 'white'"
        @input="updateOwnerIdentity"
      />
    </v-col>
    <v-col
      cols="12"
    >
      <v-row>
        <v-col
          class="pb-0"
          cols="12"
        >
          <h4>Дополнительно</h4>
        </v-col>
        <v-col
          cols="2"
        >
          <label>БИК Банка</label>
          <v-autocomplete
            :value="localState.bank_name"
            auto-select-first
            :items="banks"
            item-text="CDBank"
            item-value="CDBank"
            outlined
            dense
            hide-details="auto"
            :disabled="isDisabled('bank_name')"
            :background-color="isDisabled('bank_name') ? 'grey lighten-2': 'white'"
            :filter="filterBySearchField"
            @change="update('bank_name', $event)"
          />
        </v-col>
        <v-col
          cols="10"
        >
          <label>Примечание</label>
          <v-text-field
            :disabled="isDisabled('remark')"
            :background-color="isDisabled('remark') ? 'grey lighten-2': 'white'"
            :value="localState.remark"
            outlined
            dense
            hide-details="auto"
            @input="update('remark', $event)"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import InputNumeric from "@/components/ui/input-numeric.vue";
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import {paymentOrderDefaultState} from "@/helpers/payment-order";
export default {
  components:{
    InputNumeric,CustomDatePicker
  },
  mixins:[filterBySearchFieldMixin],
  props:{
    disabledFields:{
      required:true,
      type:Array,
    },
    currencies:{
      type:Array,
      required:true
    },
    value:{
      type:Object,
      required:true
    },
    paymentTypes:{
      type:Array,
      required:true
    },
    availablePaymentModes:{
      type:Array,
      required:true
    },
    isIndividual:{
      type:Boolean,
      default: false,
    },
    isMoreThanNineCharacters:{
      type:Boolean,
      default: false,
    },
    ownerIdentity:{
      validator:(v) => v === null || typeof v === "string",
      required:true
    },
    banks:{
      type:Array,
      required:true
    }
  },
  computed:{
    localState(){
      return this.value ?? paymentOrderDefaultState()
    },
    localOwnerIdentity(){
      return this.ownerIdentity
    },
    individual(){
      return this.isIndividual || this.isMoreThanNineCharacters
    },
  },
  methods:{
    isDisabled(key){
      return this.disabledFields.includes(key)
    },
    updateOwnerIdentity(value){
      this.$emit('update:owner-identity', value);
      if (value.length > 9) {
        this.updateIsMoreThanNineCharacters (true)
      } else this.updateIsMoreThanNineCharacters (false)
    },
    updateIsMoreThanNineCharacters(value){
      this.$emit('update:is-more-than-nine-characters', value)
    },
    update(key, value){
      this.$emit("input", {...this.value, [key]: value})
    }
  }
}
</script>
